<template>
  <div class="smallmenu">
    <router-link to="" :key="m.id" v-for="(m, index) in list">
      <span class="link-btn" :class="{ 'is-active': navIndex == index }" @click="setNav(m, index)">{{ m.name }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "skillNav",
  props: ["list"],
  data() {
    return {
      navIndex: 0,
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    setNav(m, index) {
      this.$emit("setNav", m);
      if (m) {
        this.navIndex = index;
      }
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" scoped>
// 二级导航
.smallmenu {
  text-align: center;
  .link-btn {
    padding: 20px;
    display: inline-block;
    font-size: 16px;
    &.is-active {
      color: #49C265;
    }
  }
}

/* 移动端 */
@media (max-width: 600px) {
  .smallmenu .link-btn {
    padding: 10px 5px;
  }
}
</style>
